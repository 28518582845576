import setupAxios from './setup-axios';

export default function API(apiUrl) {
  console.log('22')
  const axiosConfig = {
    baseURL: apiUrl
  };

  const axios = setupAxios(axiosConfig);

  const api = {
    fetchCurrentUser() {
      return axios.get('/', { withCredentials: true }).then(({ data }) => data);
    },

    login(user) {
      const params = { learner_data_user: { ...user } };
      return axios.post('/login', params, { withCredentials: true }).then(({ data }) => data);
    },

    join(user, reCaptchaToken = null) {
      return axios.post('/join', { user, recaptcha_response: reCaptchaToken }, { withCredentials: true })
        .then(({ data }) => data);
    },

    logout() {
      return axios.delete('/logout', { withCredentials: true }).then(({ data }) => data);
    },

    socialNetworkAuthenticate(user, socialAccount) {
      const params = {
        user,
        social_account: socialAccount
      };

      return axios.post('/social_network_authenticate', params, { withCredentials: true }).then(({ data }) => data);
    },

    sendResetPasswordInstructions(user) {
      return axios.post('/send_reset_password_instructions', { user }).then(({ data }) => data);
    },

    changePassword(user) {
      return axios.put('/change_password', { user }, { withCredentials: true })
        .then(({ data }) => data);
    },

    confirmAccount(token) {
      return axios.put('/confirm', { confirmation_token: token }, { withCredentials: true })
        .then(({ data }) => data);
    }
  };

  return api;
}
