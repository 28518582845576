// FOR iframe
import setupAxios from './setup-axios';

const MEDIA_ITEM_DEFAULT_FIELDS = [
  'id',
  'poster',
  'title',
  'description',
  'producer',
  'language_id',
  'language_code',
  'media_url',
  'group_id',
  'embedded_whitelist',
  'can_download_transcript',
  'only_audio',
  'monetization_restrict_access',
  'words_count',
  'created_at',
  'mediaitems_union'
];

export default function API(apiUrl) {
  const mediaItemUrl = (mediaItemId) => {
    return `${apiUrl}/media_items/${mediaItemId}`;
  };

  const axios = setupAxios();

  const api = {
    fetchMediaItemData(mediaItemId) {
      const videoSizes = ['small', 'medium', 'large'];
      const mediaUrlFields = videoSizes.map((size) => `media_url_${size}`);
      const params = { fields: [...MEDIA_ITEM_DEFAULT_FIELDS, ...mediaUrlFields] };
      const url = mediaItemUrl(mediaItemId);

      return axios.get(url, { params })
        .then(({ data }) => data);
    },

    fetchMediaItemKnownWordsCount(mediaItemId) {
      const url = mediaItemUrl(mediaItemId);

      return axios.get(`${url}/known_words_count`)
        .then(({ data }) => data);
    },

    fetchSyncData(mediaItemId) {
      const params = { fields: ['sync_data'] };
      const url = mediaItemUrl(mediaItemId);

      return axios.get(url, { params })
        .then(({ data }) => data);
    },

    fetchFragmentTranslations(mediaItemId, params = {}) {
      const url = `${mediaItemUrl(mediaItemId)}/fragment_translations`;

      return axios.get(url, { params })
        .then(({ data }) => data);
    },

    createFragmentTranslation(mediaItemId, params) {
      const url = `${mediaItemUrl(mediaItemId)}/fragment_translations`;

      return axios.post(url, params).then(({ data }) => data);
    },

    fetchGroupedWordTranslations(mediaItemId, translationLanguageId) {
      const url = `${mediaItemUrl(mediaItemId)}/word_translations/grouped`;

      const params = {
        translation_language_id: translationLanguageId
      };

      return axios.get(url, { params })
        .then(({ data }) => data);
    },

    fetchUserWords() {
      return axios.get(`${apiUrl}/learner_data/user_words`)
        .then(({ data }) => data);
    },

    fetchAdditionalWordTranslations(word, sourceLanguageId, targetLanguageId, excludeWords) {
      const params = {
        q: {
          by_vocabulary_text: word.text,
          vocabulary_language_id_eq: sourceLanguageId,
          language_id_eq: targetLanguageId,
          normalize_text_not_in: excludeWords
        }
      };

      const url = `${apiUrl}/vocabulary_translations`;

      return axios.get(url, { params })
        .then(({ data }) => data);
    },

    // updateUserWord(wordId, attributes) {
    //   return axios.put(`${apiUrl}/learner_data/user_words/${wordId}`, attributes)
    //     .then(({ data }) => data);
    // },

    // TODO can be removed?
    fetchSampleSentences({ word, dictionaryWordId }, requestParams = {}) {
      const params = {
        word,
        dictionary_word_id: dictionaryWordId,
        ...requestParams
      };

      return axios.get(`${apiUrl}/fragment_samples`, { params })
        .then(({ data }) => data);
    },

    // TODO move to learner data api
    getFlashCardsSession(wordsSourceType, { word_list_id } = {}) {
      const params = {
        words_source_type: wordsSourceType,
        word_list_id
      };

      return axios.post(`${apiUrl}/learner_data/flash_cards_sessions`, params)
        .then(({ data }) => data);
    },

    fetchLastFlashCard(flashCardsSessionId) {
      return axios.get(`${apiUrl}/learner_data/flash_cards_sessions/${flashCardsSessionId}/last_card`)
        .then(({ data }) => data);
    },

    requestNextFlashCard(flashCardsSessionId) {
      return axios.post(`${apiUrl}/learner_data/flash_cards_sessions/${flashCardsSessionId}/next_card`)
        .then(({ data }) => data);
    },

    verifyPayment(paymentId) {
      return axios.put(`${apiUrl}/payments/${paymentId}/verify`)
        .then(({ data }) => data);
    },

    purchaseInformation(productId, urlType) {
      return axios.get(`${apiUrl}/${urlType}/${productId}/purchase_information`).then(({ data }) => data);
    },

    fetchGroupPlaylist(groupId) {
      return axios.get(`${apiUrl}/groups/${groupId}/playlist`).then(({ data }) => data);
    },

    createPayment(params) {
      return axios.post(`${apiUrl}/payments`, params)
        .then(({ data }) => data);
    },

    removePaymentByOrder(orderId) {
      const params = {
        order_id: orderId
      };

      return axios.delete(`${apiUrl}/payments/remove_by_order`, { data: params }).then(({ data }) => data);
    },

    listVotes(resource) {
      const params = { resource };
      return axios.get(`${apiUrl}/votes`, { params })
        .then(({ data }) => data);
    },

    // TODO remove
    upVote(resource, id) {
      return axios.put(`${apiUrl}/votes/${id}/up`, { resource })
        .then(({ data }) => data);
    },

    downVote(resource, id, reason = null) {
      return axios.put(`${apiUrl}/votes/${id}/down`, { resource, reason })
        .then(({ data }) => data);
    },

    removeVote(resource, id) {
      return axios.delete(`${apiUrl}/votes/${id}`, { data: { resource } }).then(({ data }) => data);
    },

    // CLIENT API
    // TODO create client API
    updateFlashCardSetting(setting) {
      return axios.put(`${apiUrl}/client/flashcard_settings`, { setting })
        .then(({ data }) => data);
    },

    createFeedback(feedback, reCaptchaToken = null, clientVersion = null, userMediaSettings = null) {
      const url = `${apiUrl}/client/feedbacks`;
      return axios.post(
        url,
        { feedback, recaptcha_response: reCaptchaToken, client_version: clientVersion, user_media_settings: userMediaSettings }
      ).then(({ data }) => data);
    },

    createFlashcardFeedback(params) {
      return axios.post(`${apiUrl}/client/feedbacks/create_flash_card_feedback`, params)
        .then(({ data }) => data);
    },

    attachMediaToFeedback(feedbackId, attachment) {
      const formData = new FormData();
      formData.append('file', attachment);
      return axios.post(`${apiUrl}/client/feedbacks/${feedbackId}/attachment`, formData)
        .then(({ data }) => data);
    },

    updateProfile(profile) {
      return axios.put(`${apiUrl}/client/users`, { profile }, { withCredentials: true }).then(({ data }) => data);
    },

    watchContent(mediaItemId) {
      const url = mediaItemUrl(mediaItemId);

      return axios.post(`${url}/watch_content`)
        .then(({ data }) => data);
    },

    watchedProgress(mediaItemId, playedTime) {
      const url = mediaItemUrl(mediaItemId);

      const params = {
        played_time: playedTime
      };

      return axios.post(`${url}/watched_progress`, params)
        .then(({ data }) => data);
    },

    fetchMediaItemFoldersTree(userId, roles = [], fields = []) {
      const url = `${apiUrl}/folders/mediaitem_folders_tree`;
      const params = {
        user_id: userId,
        roles,
        fields
      };

      return axios.get(url, { params, withCredentials: true })
        .then(({ data }) => data);
    },

    createFolder(name, parentId = null, fields = null) {
      const params = { name, parent_id: parentId, fields };

      return axios.post(`${apiUrl}/folders`, params, { withCredentials: true })
        .then(({ data }) => data);
    },

    addMediaItemToFolder(folderId, mediaItemId) {
      const url = `${apiUrl}/folders/${folderId}/add_media_item`;
      const params = { media_item_id: mediaItemId };

      return axios.post(url, params, { withCredentials: true })
        .then(({ data }) => data);
    },

    // addGroupToFolder(folderId, groupId) {
    //   const url = `${apiUrl}/folders/${folderId}/add_group`;
    //   const params = { group_id: groupId };
    //
    //   return axios.post(url, params, { withCredentials: true })
    //     .then(({ data }) => data);
    // },

    removeMediaItemFromFolder(folderId, mediaItemId) {
      const url = `${apiUrl}/folders/${folderId}/remove_media_item`;
      const params = { media_item_id: mediaItemId };

      return axios.delete(url, { data: params }, { withCredentials: true })
        .then(({ data }) => data);
    }

    // removeGroupFromFolder(folderId, groupId) {
    //   const url = `${apiUrl}/folders/${folderId}/remove_group`;
    //   const params = { group_id: groupId };
    //
    //   return axios.delete(url, { data: params }, { withCredentials: true })
    //     .then(({ data }) => data);
    // },
  };

  return api;
}
